import dynamic from 'next/dynamic';

import HeroSection from 'components/HeroSection';
// eslint-disable-next-line @typescript-eslint/promise-function-async
const BuildFasterSection = dynamic(() => import('components/BuildFasterSection'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const CloudFeatures = dynamic(() => import('components/CloudFeatures'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const CloudTestimonials = dynamic(() => import('components/CloudTestimonials'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const Pricing = dynamic(() => import('components/Pricing'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const FAQSection = dynamic(() => import('components/FAQSection'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const GetStartedFooterSection = dynamic(() => import('components/GetStartedFooterSection'));
// eslint-disable-next-line @typescript-eslint/promise-function-async
const GetStartedSection = dynamic(() => import('components/GetStartedSection'));

export default function HomePage() {
  return (
    <div className="relative flex flex-col overflow-hidden">
      <HeroSection />
      <GetStartedSection />
      <BuildFasterSection />
      <CloudFeatures />
      <CloudTestimonials />
      <Pricing />
      <FAQSection />
      <GetStartedFooterSection />
    </div>
  );
}
